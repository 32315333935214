import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const gallerySaveOrUpdate =()=>{
    const hata = ref(null);
    const path = apiPath;
    const router = useRouter();
    let users = JSON.parse(localStorage.getItem('user'));
    const SaveOrUpdate = async(update,images)=>{
        hata.value = null;
        let newImages = images.split(',')[1]
        try {
            const res = await fetch(path + "gallery/saveOrUpdate", {
                method: 'POST',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "TypeId" :update.TypeId,
                    "TypeName" : update.TypeName,
                    "Title" : update.Title,
                    "ShortDescription" : update.ShortDescription,
                    "CompanyName" : update.CompanyName,
                    "IsActive" : true,
                    "IsDelete" : false,
                    "Rgb" : "",
                    "FileImage" : newImages,
                    "TokenKey": localStorage.getItem('token'),                    
                    "UserId":users.id,
                    "RequestMethodName" :"/api/gallery/saveOrUpdate",
                    "RequestMethodType" : "POST"
                }), 
            })
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})               
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
        }
    }
    
    return {SaveOrUpdate}
}

export default gallerySaveOrUpdate